<template>
<v-container grid-list-xs>
  <div class="d-flex mt-4 mb-6">
    <h1 class="text text-h4 titulo">Planificador de Planta</h1>
    <v-spacer></v-spacer>
    <strong class="align-self-center clock mr-4 titulo text-h5">{{Date.now()|moment('DD-MMM-YYYY')}}</strong>
    <digital-clock :blink="true" class="clock align-self-center titulo text-h5"/>
    <v-spacer></v-spacer>
    <h3 v-if="mustSave" class="red--text align-self-center mr-4"><v-icon class='red--text mr-2'>fas fa-exclamation-triangle</v-icon>Recuerde Guardar los Cambios</h3>
  </div>
  <v-progress-linear
    color="primary"
    indeterminate
    rounded
    height="6"
    v-show="loading || saving"
  ></v-progress-linear>

    <v-slide-group>
      <v-slide-item v-for='machine in machines' 
        :key='machine.id'
        >
        <v-btn :class="{'primary':tab2==machine.id}" @click="()=>{tab2=machine.id}">
          {{machine.name}}
        
        </v-btn>
      </v-slide-item>  
    </v-slide-group>

    <div v-if="!tab2 && !loading" class="d-flex justify-start text-h6 mt-8">
      <v-icon class='info--text mr-2'>fas fa-circle-info</v-icon>
      Seleccione un área de producción para continuar
    </div>
    <machine-planner-alone
    v-else 
    :machine_id='tab2' 
    @displayWorkOrder='displayWorkOrder'
    :dragEnable="true"
    />
      

  <v-dialog  v-model='showWorkOrder' max-width="1200">
    <v-card class="pa-10" >
      <work-order-card :work_order='workOrderDisplay' @close="showWorkOrder=false" :loading="workOrderOnDisplayLoading"/> 
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import DigitalClock from "vue-digital-clock";

import {getWorkOrder,fetchMachines} from '@/api/production'


import WorkOrderCard from '@/components/production/WorkOrderCard'
import notifications from '@/utils/notifications'
import MachinePlannerAlone from '@/components/production/MachinePlannerAlone.vue'

export default {
  name:'MainPlanner',
  components:{DigitalClock,WorkOrderCard,MachinePlannerAlone},
  data() {
    return {
      machines: [],
      showingMachines:[],
      mustSave:false,
      showWorkOrder:false,
      workOrderDisplay:{},
      work_order_card_loading:false,
      workOrderOnDisplayLoading:false,
      saving:false,
      tab:null,
      loading:false,
      machineDetail:null,
      tab2:null,
    }
  },
  async created(){
    this.fetch()
  },
  methods:{
    async displayWorkOrder(item){
      this.workOrderOnDisplayLoading=true
      this.showWorkOrder=true
      await getWorkOrder(item.product.work_order.id)
      .then((data)=>{
        this.workOrderDisplay=data
        this.workOrderOnDisplayLoading=false
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
        this.workOrderOnDisplayLoading=false
        this.showWorkOrder=false
      })
    },
    showMustSave(){
        this.mustSave=true
    },
    async fetch(){
      this.loading=true
      const query='enabled=true&ordering=sort'
      await fetchMachines(query)
      .then((res)=>{
        this.machines=res
        if(this.$store.state.company?.load_planner_on_initial){
          this.tab2=res[0].id
        } else {
          this.tab2=null
        }
      })
      .catch((e)=>{
        notifications.showError('Error Cargando Datos '+e)
      })
      this.loading=false
    }
    
  },
  watch:{
    machines:{
      deep:true,
      handler: 'showMustSave'
    }

  }
    

}
</script>

<style lang="scss">
  .clock{
    font-size: x-large;
    font-weight: 500;
  }
</style>