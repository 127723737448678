<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
    <v-col align-items-center>
      <h1 class="text text-h4 titulo">Facturas</h1>
    </v-col>
    <v-col class="text-end" justify-end>
      <div class="d-flex justify-end">
        <export-invoices :filter="filter" :options="options" :disabled="loading"/>
        <Can I="create" a="Invoice">
          <v-btn class="ml-4" color="success" @click="$router.push({name:'CreateDTE',params:{id:-1,tipo:30}})">
              <v-icon class="mr-2">fas fa-plus</v-icon>Nueva
          </v-btn>
        </Can>
      </div>
    </v-col>
  </v-row>
    <v-card class='pa-4'>
  <v-row justify="space-between">
    <v-col cols="12" md="6" align-self="center">
      <v-text-field 
      hide-details 
      :disabled="loading" 
      dense 
      v-model='filter.created_after' 
      class="mr-4" 
      outlined 
      type="date" 
      label="Emitida Desde:"
      @change="()=>{
            options.page=1;
            fetch()
          }"
      />
    </v-col>
    <v-col cols="12" md="6"  align-self="center">
      <v-text-field 
      hide-details  
      :disabled="loading" 
      dense 
      v-model='filter.created_before' 
     
      outlined 
      type="date" 
      label="Emitida Hasta:" 
      @change="()=>{
            options.page=1;
            fetch()
          }"
      />
    </v-col>
    <v-col cols="12" md="6" align-self="center">
      <Can I="readAll" a="SalesNote">
        <v-select
          v-model="filter.tipo"
          :items="[
            {
              value:30,
              text:'Factura'
            },
            {
              value:60,
              text:'Nota de Crédito'
            }
          ]"
          item-text="text"
          item-value="value"
          label="Tipo"
          placeholder="Todos"
          prepend-inner-icon="filter_alt"
          outlined
          clearable
          :disabled="loading" 
          dense
          @change="()=>{
            options.page=1;
            fetch()
          }"
        />
      </Can>
    </v-col>
    <v-col cols="12" md="6" align-self="center">
      <Can I="readAll" a="SalesNote">
        <v-select
          v-model="filter.seller"
          :items="sellers"
          :disabled="loading" 
          item-text="email"
          item-value="id"
          label="Vendedor"
          placeholder="Todos"
          prepend-inner-icon="filter_alt"
          outlined
          clearable
          dense
          @change="()=>{
            options.page=1;
            fetch()
          }"
        />
      </Can>
    </v-col>
    
    <v-col cols="12" md="6" align-self="center">
      <Can I="readAll" a="SalesNote">
        <v-select
          v-model="filter.not_null"
          :items="[{'text':'Emitido','value':true}]"
          item-text="text"
          item-value="value"
          label="Estatus"
          placeholder="Todos"
          prepend-inner-icon="filter_alt"
          outlined
          clearable
          :disabled="loading" 
          dense
          @change="()=>{
            options.page=1;
            fetch()
          }"
        />
      </Can>
    </v-col>
    <v-col cols="12">
      <v-row justify="space-around">
        <number-card title="Facturado con IVA">
          {{ total | currency("$", 0) }}
        </number-card>
        <number-card title="Pagado">
          {{ payed | currency("$", 0) }}
          <small>[{{
            (payed / total) | percent
          }}]</small> 
        </number-card>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        v-model="search"
        @keyup="searchTimeOut"
        label="Buscar"
        append-icon="fas fa-search"
        :disabled="loading"
      ></v-text-field>
    </v-col>
  </v-row>
  
  <v-row>
    <v-col>
      <v-data-table
          class=""
          :headers="computedHeaders"
          :items="dtes"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalDtes"
          :item-class="(item)=>{return item.tipo==60?'row-style-nc':'row-style-normal'}"
          :search="search"
          sort-by='date_emited'
          :sort-desc="false"
          @click:row='displayDetails'
          @update:options="fetch"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }">
          <template v-slot:item.document_id="{ item }">
            <small :class="`${estatuses.find(e=>e.value==item.estatus).color}--text`">{{estatuses.find(e=>e.value==item.estatus).text}}</small>
            <v-chip
            v-if="item.tipo==60" 
            x-small
            color="error"
            class="ml-2"
            >
            NC
            </v-chip>
            <div class="d-block">
              {{item.document_id}}
            </div>
          </template>
          <template v-slot:item.date_emited="{ item }">
            <div class="d-block">
              {{item.date_emited| moment('DD/MM/YYYY')}}
            </div>
          </template>
          <template v-slot:item.rznSocRecep="{ item }">
            <small>{{item.rutRecep}}</small>
            <div class="d-flex d-block">
               {{item.rznSocRecep}}
            </div>
          </template>
          <template v-slot:item.get_total="{ item }">
            {{item.get_total| currency('$', 0)}}
          </template>
          <template v-slot:item.payed="{ item }">
            <div v-if="item.tipo==60" class="text-center">-</div>  
            <div  v-else  :class="(item.get_total - item.get_payed)>0?'red--text':''">{{item.get_payed| currency('$', 0)}}</div>
          </template>
          
          <template v-slot:item.actions="{ item }">
            <v-menu
            bottom left offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
                v-bind="attrs"
                v-on="on"
                text
                 class="ml-2">more_vert</v-icon> 
            </template>
            <v-list dense>
              <Can I="update" a="Invoice">
                <v-list-item>
                  <v-btn 
                  text 
                  small
                  :disabled="item.tipo==60" 
                  @click.stop="()=>{
                    itemToPayment=item
                    toPayment=true
                    }" 
                  >
                  <v-icon class="mr-2">fas fa-money-check-alt</v-icon>Pagos
                  </v-btn>
                </v-list-item>
              </Can>
              <Can I="update" a="Invoice">
                <v-list-item v-if="Boolean(item.estatus<2)">
                  <v-btn 
                  text 
                  small 
                  :disabled="item.tipo==60"
                  @click.stop="()=>{
                    invoice=item
                    showConfirmReceived=true
                    }" 
                  >
                  <v-icon class="mr-2">fas fa-sync</v-icon>Recepcionar
                  </v-btn>
                </v-list-item>
              </Can> 
              <Can I="delete" a="Invoice">
                <v-list-item v-if="Boolean(item.estatus<3)">
                  <v-btn 
                  text 
                  small 
                  @click.stop="()=>{
                    invoice=item
                    showConfirmCancel=true
                    }" 
                  class="error--text">
                  <v-icon class="mr-2">fas fa-trash</v-icon>Anular
                  </v-btn>
                </v-list-item> 
              </Can>
            </v-list>
          </v-menu>
          </template>
        </v-data-table>
    </v-col>
  </v-row>
    </v-card>
  <confirm
    v-if="invoice"
    v-model="showConfirmCancel" 
    :message="`Confirma la anulación de la Factura #${invoice.document_id}?`" 
    @ok="updateEstatus(3)"
    @cancel="showConfirmCancel=false"/>
  <confirm
    v-if="invoice"
    v-model="showConfirmReceived" 
    :message="`Confirma el cambio de Estatus de la Factura #${invoice.document_id} emitida a ${invoice.rznSocRecep} a Recepcionada?`" 
    @ok="updateEstatus(2)"
    @cancel="showConfirmReceived=false"/>
  <v-dialog v-model="showDetail" max-width="1200">
    <v-card class="px-4">  
      <invoice-detail :invoice="detailToDisplay" @close="showDetail=false"/>
    </v-card>
  </v-dialog>
  <v-dialog v-model="toPayment" max-width="1200">
    <v-card class="pa-6">
      <v-card-title class="titulo text-h5">Historial de Pagos</v-card-title>
      <invoice-pay-detail :invoice="itemToPayment" @refresh="()=>{
        toPayment=false
        fetch()
        }"/>
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import {fetchDTE,updateDTE} from '@/api/salesNotes'
import {fetchSellers} from '@/api/auth'
import notifications from '@/utils/notifications'
import ExportStock from '@/components/stocks/ExportStock'
import LinkPOtoOT from '@/components/stocks/LinkPOtoOT'
import DetailedPurchaseOrder from '@/components/stocks/DetailedPurchaseOrder'
import {pdfPurchaseOrder} from '@/doc_generators/purchaseOrder'
import InvoicePayDetail from '@/components/sales/InvoicePayDetail'
import InvoiceDetail from '@/components/sales/InvoiceDetail'
import NumberLabel from '@/components/utils/NumberLabel'
import NumberCard from '@/components/utils/NumberCard'
import moment from 'moment'
import ExportInvoices from '@/components/sales/ExportInvoices.vue'

const numeral = require('numeral');

export default {
  name:'Invoices',
  components:{ExportStock,DetailedPurchaseOrder,LinkPOtoOT,InvoicePayDetail,InvoiceDetail,NumberLabel,NumberCard,ExportInvoices},
  data() {
    return {
      loading: false,
      loadingDetail:false,
      showDetail:false,
      detailToDisplay:'',
      dtes:[],
      sellers:[],
      invoice:'',
      timer:'',
      options:{},
      totalDtes:'',
      showDialog:false,
      showConfirmApprove:false,
      showConfirmCancel:false,
      showConfirmReceived:false,
      toApprove:'',
      toLinkOt:false,
      toPayment:false,
      itemToPayment:'',
      
      estatuses:[
        {
          value:1,
          text:'Emitido',
          color:'info'
        },
        {
          value:2,
          text:'Recepcionado',
          color:'success'
        },
        {
          value:3,
          text:'Anulado',
          color:'error'
        },
      ],
      
      search:'',
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
        tipo:'',
        seller:'',
        not_null:'true',
      },
      
      headers: [
      {
        text: 'Número',
        align: 'start',
        value: 'document_id',
        class: 'table-header',
        show:true,
        align:'start'
      },
      {
        text: 'Fecha',
        align: 'start',
        value: 'date_emited',
        class: 'table-header',
        show:true
      },
      {
        text: 'Razón Social',
        align: 'start',
        value: 'rznSocRecep',
        class: 'table-header',
        show:true
      },
      
      {
        text: 'OT',
        align: 'start',
        value: 'work_order',
        class: 'table-header',
        sortable:true,
        show:true
      },
      {
        text: 'Cot.',
        align: 'start',
        value: 'sales_note',
        class: 'table-header',
        sortable:false,
        show:true
      },
      {
        text: 'Total',
        value: 'get_total',
        class: 'table-header',
        show:true,
        sortable:false,
        align:'end'
      },
      {
        text: 'Pagado',
        value: 'payed',
        class: 'table-header',
        show:true,
        sortable:false,
        align:'end'
      },
      {
        text: '',
        align: 'start',
        value: 'actions',
        class: 'table-header',
        sortable:false,
        show:true,
        align:'end'
      },
    ],
      subtotal:0,
      iva:0,
      total:0,
      payed:0,
    }
  },
  methods:{
    totalOrder(item){
      const total = item.details.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0)
      return total-total*parseFloat(item.discount)/100
    },
    getStatusName(item){
      return this.estatuses.find(i=>i.value==item.estatus).text
    },
    getStatusColor(item){
      return this.estatuses.find(i=>i.value==item.estatus).color
    },
    displayDetails(item){
      this.detailToDisplay=item
      this.showDetail=true
    },
    print(item){
      pdfPurchaseOrder(item)
    },
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort=''
      if(sortBy[0]=='customer_name'){
        sort = 'customer__name'
      }
      else {
        sort = sortBy[0]
      }
      if (!this.filter.not_null) this.filter.not_null=''
      if (!this.filter.tipo) this.filter.tipo=''
      if (!this.filter.seller) this.filter.seller=''
      
      //Filtrando por el usuario si no tiene permisos
      if (!this.$can('readAll', 'SalesNote')){
          const user = this.$store.getters.getUser
          this.filter.seller=user.id
        }
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&&page=${page}&&page_size=${itemsPerPage}&&search=${this.search}&&tipo=${this.filter.tipo}&&emited_after=${this.filter.created_after}&emited_before=${this.filter.created_before}&customer__seller__id=${this.filter.seller}&not_null=${this.filter.not_null}`
      await fetchDTE(query)
      .then(res=>{
        this.totalDtes=res.count
        this.dtes=res.results
        this.subtotal=res.sub_total
        this.iva=res.iva
        this.total=res.total
        this.payed=res.payed
        
      })
      .catch((err)=>{
        notifications.showError('Error cargando órdenes '+err)
      })
      this.loading=false
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.options.page=1
          this.fetch()
      }, 800);
    },
    async updateEstatus(estatus){
      await updateDTE({id:this.invoice.id,estatus:estatus})
      .then(res=>{
        this.showConfirmCancel=false  
        this.invoice=''
        notifications.showSuccess('Factura #'+res.document_id+' anulada!')
        this.fetch()
      })
      .catch(err=>{
        notifications.showError('Error anulando factura '+err)
      })
    },
  },
  async mounted(){
    this.loading=true
    this.fetch()
    this.sellers=await fetchSellers()
    this.loading=false
  },
  computed:{
    computedHeaders(){
      return this.headers
    }
  }
}
</script>

<style lang="scss">
.row-style-normal{
    cursor: pointer;
 }
.row-style-nc{
    color: red;
 }
</style>