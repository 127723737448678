import axios from "axios"

export async function excelWorkOrders(query) {
  return await axios.get(
          `defontana/excel-workorders?${query}`,{
              responseType: 'blob',
              timeout: 1000 * 60
          })
      .then(blob => {
          return blob.data
      })
      .catch(e  => {if(e.response.data.error) throw e.response.data.error})
}