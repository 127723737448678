<template>
  <v-container>
      <h1 class="text text-h4 titulo mb-6">
        <v-icon class="mr-2" large>event_available</v-icon>Calendario de Compromisos [mts<sup>2</sup>]
      </h1>
      <div class="d-flex justify-space-between align-center mb-2">
        <div class="d-flex align-center">
          <v-select
            v-model="line"
            :items="getLines"
            item-text="name"
            item-value="id"
            label="Todas las lineas"
            placeholder="Todas las Líneas"
            single-line
            outlined
            hide-details
            dense
            clearable
            prepend-inner-icon="filter_alt"
            @change="handleLineChange"
          />
        </div>
        <div class="d-flex">
          
          <v-sheet color="indigo" dark>
            <div class="py-2 px-4">
              <div class="text-h4 font-weight-light mb-2 text-center">
                {{totalCompromised}} mts<sup>2</sup>
              </div>
              <div class="subheading font-weight-light text-center">
                Comprometido
              </div>
            </div>
          </v-sheet>
          <v-sheet color="green" dark>
            <div class="py-2 px-4">
              <div class="text-h4 font-weight-light mb-2 text-center">
                {{totalFinished}} mts<sup>2</sup>
              </div>
              <div class="subheading font-weight-light text-center">
                Terminado
              </div>
            </div>
          </v-sheet>
          <v-sheet color="error" dark>
            <div class="py-2 px-4">
              <div class="text-h4 font-weight-light mb-2 text-center">
                {{totalPending}} mts<sup>2</sup>
              </div>
              <div class="subheading font-weight-light text-center">
                Pendiente
              </div>
            </div>
          </v-sheet>
          
        </div>
      </div>
        <div class="">
          <v-sheet
            tile
            height="54"
            class="d-flex align-center"
          >
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.prev()"
              :disabled="loading"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div class="text text-h6 titulo">{{currentMonth}}</div>
            <v-spacer></v-spacer>
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.next()"
              :disabled="loading"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-sheet>
          <v-sheet height="600">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
                v-show="loading"
              ></v-progress-linear>
            <v-calendar
              v-show="!loading"
              ref="calendar"
              v-model="value"
              :weekdays="weekday"
              :type="type"
              :event-height="25"
              :events="events"
              :event-more="false"
              :event-overlap-mode="mode"
              :event-overlap-threshold="100"
              :event-color="getEventColor"
              @change="changeinCalendar"
              @click:event="showEvent"
              
            >
          
            <template v-slot:event="{ day, event }">
            <div class="text-center">
              <v-progress-circular
                v-if="event.loading"
                indeterminate
                dark
                :width="3"
                :size="15"
              ></v-progress-circular>
              <div v-else class="font-weight-bold">
                {{event.value }}
              </div>
            </div>
            </template>
          
          </v-calendar>
            <!-- Tabla de productos comprometidos -->
            <v-dialog  v-model='selectedOpen' max-width="1200">
              <v-card class="">
                <v-toolbar dense :color="selectedEvent.color" class="justify-space-between">
                  <div v-if="selectedEvent.type=='compromised'" class="text white--text text-h6 mr-2">Comprometido</div>
                  <div v-else-if="selectedEvent.type=='finished'" class="text white--text text-h6 mr-2">Terminado</div>
                  <div v-else class="text white--text text-h6 mr-2">Pendiente</div>
                  <div class="text white--text text-h6">{{selectedEvent.start | moment('DD/MM/YYYY')}}</div>
                  <v-spacer></v-spacer>
                  <div>
                    <vue-json-to-csv
                      :json-data="selectedEventProducts"
                      :labels="labels"
                      :csv-title="selectedEventTitle"
                      :separator="';'"
                      >
                      <v-btn dark text>
                        <v-icon class="mr-2">fas fa-file-export</v-icon>
                      </v-btn>
                    </vue-json-to-csv> 
                  </div>
                </v-toolbar>
                <v-data-table
                  :headers="headersSelectedEvent"
                  :items="selectedEventProducts"
                  :items-per-page="10"
                  class="pa-3"
                  :footer-props="{
                    'items-per-page-options': [10,20]
                  }"
                  >
                  <template v-slot:item.work_order="{ item }">
                    <show-work-order :work_order_id="item.work_order">{{item.work_order}}</show-work-order>
                  </template>
                  <template v-slot:item.calculatedTotalMts2="{ item }">
                    <span class="info--text">{{item.calculatedTotalMts2 | number('0,0.0')}}</span>
                  </template>
                  <template v-slot:item.completed_mts2="{ item }">
                    <span class="green--text">{{item.completed_mts2 | number('0,0.0')}}</span>
                    
                  </template>
                  <template v-slot:item.pending="{ item }">
                    <span class="error--text">{{item.pending | number('0,0.0')}}</span>
                    
                  </template>
                </v-data-table>
              </v-card>
            </v-dialog>
          </v-sheet>
        </div>
        <v-dialog  v-model='display' max-width="1200">
          <v-card class="pa-4" >
            <work-order-card :work_order='workOrderOnDisplay' @close="display=false" :loading="workOrderOnDisplayLoading"/> 
          </v-card>
        </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import numeral from 'numeral'
import { fetchMontlyCompromised, fetchMontlyCompromisedDetail } from '@/api/reports'
import notifications from '@/utils/notifications'
import NumberCard from '@/components/utils/NumberCard.vue'
import VueJsonToCsv from 'vue-json-to-csv'
export default {
  name:'CompromiseCalendar2',
  components:{
    VueJsonToCsv,
    NumberCard
  },
  data() {
    return {
      
      currentMonth: '',
      workOrders:[],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: 'Lunes - Domingo', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Lunes - Viernes', value: [1, 2, 3, 4, 5] },
      ],
      value: '',
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1','orange darken-4'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      workOrderOnDisplay:{},
      workOrderOnDisplayLoading:false,
      display:false,
      loading:false,
      line:null,
      headersSelectedEvent:[
        {text:'OT',value:'work_order'},
        {text:'Cliente',value:'customer'},
        {text:'Proyecto',value:'title'},
        {text:'Total',value:'compromised',align:'right'},
        {text:'Terminados',value:'finished',align:'right'},
        {text:'Pendientes',value:'pending',align:'right'},
      ],
      productionCalendar:false,
      labels:{ 
        work_order: { title: 'OT' },
        customer:{title:'Cliente'},
        title:{title:'Proyecto'},
        seller:{title:'Vendedor'},
        compromised:{title:'Total'},
        finished:{title:'Terminado'},
        pending:{title:'Pendiente'},
      },
      totalCompromised:0,
      totalFinished:0,
      totalPending:0,
      eventMonth:0,
      eventYear:0
    }
  },
  methods:{
      getEventColor (event) {
        return event.color
      },
      showEvent ({ nativeEvent, event }) {
        const open = async () => {
          this.selectedEvent = event
          this.selectedEvent.loading = true
          const workOrders = await this.fetchDetails(event.start)
          .finally(()=>{
            this.selectedEvent.loading=false
          })
          if(workOrders.length>0){
            this.selectedEvent={
              type:event.type,
              workOrders:workOrders,
              start:event.start,
              color:event.color
            }
          }

          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      changeinCalendar(event){
        this.eventMonth=event.start.month,
        this.eventYear=event.start.year
        this.currentMonth=moment(event.start.date).format('MMMM').toUpperCase()+' - '+event.start.year
        this.fetchData()
      },
      async fetchData(){
        const DECIMAL_FACTOR=10
        this.events=[]
        
        this.loading=true
        const year=this.eventYear
        const month=this.eventMonth
        let query=`year=${year}&month=${month}`
        if(this.line) query = query+`&line=${this.line}`
        
        const events = await fetchMontlyCompromised(query)
        .catch(error=>{
          console.log(error)
          notifications.error(error)
        })
        .finally(()=>{
          this.loading=false
        })
        
        if(events.length>0){
          events.forEach(ev=>{
            this.events.push({
              name:`${ev?.compromised/DECIMAL_FACTOR}`,
              value: ev?.compromised/DECIMAL_FACTOR,
              start: ev?.date,
              color:'indigo',
              type:'compromised',
              loading:false

            })
            this.events.push({
              name:`${ev?.finished/DECIMAL_FACTOR}`,
              value: ev?.finished/DECIMAL_FACTOR,
              start: ev?.date,
              color:'green',
              type:'finished',
              loading:false
            })
            this.events.push({
              name:`${ev?.pending/DECIMAL_FACTOR}`,
              value: ev?.pending/DECIMAL_FACTOR,
              start: ev?.date,
              color:'error',
              type:'pending',
              loading:false
            })
          })
        }

        // Calculo las sumas de cada uno para las tarjetas
        this.totalCompromised=numeral(events.reduce((acc,val)=>{return acc+val.compromised/DECIMAL_FACTOR},0)).format('0,0[.]0')
        this.totalFinished=numeral(events.reduce((acc,val)=>{return acc+val.finished/DECIMAL_FACTOR},0)).format('0,0[.]0')
        this.totalPending=numeral(events.reduce((acc,val)=>{return acc+val.pending/DECIMAL_FACTOR},0)).format('0,0[.]0')
        
      },
      async fetchDetails(date){
        const DECIMAL_FACTOR=10
        let query = `date=${date}`
        console.log(date)

        if(this.line) query=query+`&line=${this.line}`

        const workOrders=await fetchMontlyCompromisedDetail(query)
        .catch(error=>{
          console.log(error)
          notifications.error(error)
          
        })
        .finally(()=>{
          
        })
        if(workOrders?.length>0){
          return workOrders.map(w=>({
            ...w,
            compromised:w.compromised/DECIMAL_FACTOR,
            finished:w.finished/DECIMAL_FACTOR,
            pending:w.pending/DECIMAL_FACTOR,
          }))
        }
        else {
          return []
        }

      },
      handleLineChange(){
        this.fetchData()
      }
  },
  computed:{
    getLines(){
      return this.$store.getters.getLines
    },
    selectedEventProducts(){
      const { type, workOrders } = this.selectedEvent
      if(type=='compromised') return workOrders
      if(type == 'finished') return workOrders.filter(wO=>wO.pending==0)
      if(type=='pending') return workOrders.filter(wO=>wO.finished==0)
    },
    selectedEventTitle(){
      if(this.selectedEvent?.type=='compromised'){
        return 'Comprometido_'+this.$options.filters.moment(this.selectedEvent?.start,'DD/MM/YYYY')
      }
      else if(this.selectedEvent?.type=='completed'){
        return 'Completado_'+this.$options.filters.moment(this.selectedEvent?.start,'DD/MM/YYYY')
      }
      else if(this.selectedEvent?.type=='pending'){
        return 'Pendiente_'+this.$options.filters.moment(this.selectedEvent?.start,'DD/MM/YYYY')
      }
    }
  },
}
</script>

<style  lang="scss">
.v-past{
  background-color: lightgrey !important;
}

.v-calendar-weekly__day-label{
  .v-btn{
    height: 20px !important;
  }
}

</style>